
import axios from 'axios';
const api_url = process.env.VUE_APP_API_URL;
export const apiServices = {
    callApi
}

function callApi(method, url, data, multipart = false, token) {
    let config = {
        method: method,
        url: api_url + url,
        data: data,
    };
    if (multipart) {
        config.headers = { "content-type": "multipart/form-data" };
    }
    if (token) {
        config.headers = { "Authorization": `Bearer ${token}` };
    }
    try {
        let res = axios(config);
        return res;
    } catch (e) {
        // return e.response
    }
}