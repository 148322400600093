import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export function initializeEcho(accessToken) {
    console.log("auth url -->", process.env.VUE_APP_AUTH_URL);
    console.log("Access Token:", accessToken);
    if (accessToken) {
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.VUE_APP_WEBSOCKET_KEY,
            cluster: 'mt1',
            wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
            wsPort: process.env.VUE_APP_PUSHER_PORT,
            wssPort: process.env.VUE_APP_PUSHER_PORT,
            forceTLS: false,
            enabledTransports: ['ws', 'wss'],
            disabledStats: true,
            authEndpoint: process.env.VUE_APP_AUTH_URL,
            channelAuthorization: {
                endpoint: process.env.VUE_APP_AUTH_URL,
                headers: {
                    Authorization: `Bearer ${JSON.parse(accessToken)}`,
                },
            },
            auth: {
                headers: {
                    Authorization: `Bearer ${JSON.parse(accessToken)}`, // Use accessToken directly
                },
            },
        });
        console.log('Laravel Echo reinitialized after refresh:', window.Echo);

        if (window.Echo.connector) {
            console.log("After Echo initialization:", window.Echo.connector.options.authEndpoint);
        } else {
            console.warn("Echo.connector is undefined.");
        }
    } else {
        console.warn('No access token found. Echo not initialized.');
    }
}
