import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import { vMaska } from "maska"
import i18n from "./i18n"

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"

// PINIA
import pinia from '@/state/pinia'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import vue3GoogleLogin from 'vue3-google-login'

import Pusher from 'pusher-js';

import { initializeEcho } from './helpers/echo-service';
import { apiServices } from './helpers/api-service';

if (process.env.VUE_APP_NODE_ENV === 'production') {
    console.log = () => { };
    Pusher.logToConsole = false;
} else {
    Pusher.logToConsole = true;
}


// Call this function on application startup
// import Echo from 'laravel-echo';

// function initializeEcho() {
//     const accessToken = localStorage.getItem('access_token');
//     console.log("auth url -->", process.env.VUE_APP_AUTH_URL);
//     console.log("Access Token:", accessToken);
//     if (accessToken) {
//         window.Pusher = Pusher;
//         window.Echo = new Echo({
//             broadcaster: 'pusher',
//             key: process.env.VUE_APP_WEBSOCKET_KEY,
//             cluster: 'mt1',
//             wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
//             wsPort: process.env.VUE_APP_PUSHER_PORT,
//             wssPort: process.env.VUE_APP_PUSHER_PORT,
//             forceTLS: false,
//             enabledTransports: ['ws', 'wss'],
//             disabledStats: true,
//             authEndpoint: process.env.VUE_APP_AUTH_URL,
//             channelAuthorization: {
//                 endpoint: process.env.VUE_APP_AUTH_URL,
//                 headers: {
//                     Authorization: `Bearer ${JSON.parse(accessToken)}`,
//                 },
//             },
//             auth: {
//                 headers: {
//                     Authorization: `Bearer ${JSON.parse(accessToken)}`, // Use accessToken directly
//                 },
//             },
//         });
//         console.log('Laravel Echo reinitialized after refresh:', window.Echo);

//         if (window.Echo.connector) {
//             console.log("After Echo initialization:", window.Echo.connector.options.authEndpoint);
//         } else {
//             console.warn("Echo.connector is undefined.");
//         }
//     } else {
//         console.warn('No access token found. Echo not initialized.');
//     }
// }
const accessToken = localStorage.getItem('access_token');
initializeEcho(accessToken);

async function fetchGoogleClientIdAndInitializeApp() {
    try {
        const res = await apiServices.callApi('post', 'fetch-google-client-id', {}, false, null);
        if (res.data.status) {
            const googleClientId = res.data.data.google_client_id;

            // Initialize Vue app with the fetched client ID
            createApp(App)
                .use(pinia)
                .use(router)
                .use(require('vue-chartist'))
                .use(BootstrapVueNext)
                .use(VueApexCharts)
                .use(vClickOutside)
                .use(i18n)
                .use(registerScrollSpy)
                .use(vue3GoogleLogin, { clientId: googleClientId }) // Use fetched client ID here
                .directive('maska', vMaska)
                .mount('#app');
        } else {
            createApp(App)
                .use(pinia)
                .use(router)
                .use(require('vue-chartist'))
                .use(BootstrapVueNext)
                .use(VueApexCharts)
                .use(vClickOutside)
                .use(i18n)
                .use(registerScrollSpy)
                // .use(vue3GoogleLogin, { clientId: googleClientId }) // Use fetched client ID here
                .directive('maska', vMaska)
                .mount('#app');
        }
    } catch (error) {
        createApp(App)
            .use(pinia)
            .use(router)
            .use(require('vue-chartist'))
            .use(BootstrapVueNext)
            .use(VueApexCharts)
            .use(vClickOutside)
            .use(i18n)
            .use(registerScrollSpy)
            // .use(vue3GoogleLogin, { clientId: googleClientId }) // Use fetched client ID here
            .directive('maska', vMaska)
            .mount('#app');
        console.error('Error fetching Google client ID:', error);
    }
}

fetchGoogleClientIdAndInitializeApp();


// createApp(App)
//     .use(pinia)
//     .use(router)
//     .use(require('vue-chartist'))
//     .use(BootstrapVueNext)
//     .use(VueApexCharts)
//     .use(vClickOutside)
//     .use(i18n)
//     .use(registerScrollSpy)
//     .use(vue3GoogleLogin, {
//         clientId: google_client_id
//     })
//     .directive("maska", vMaska)
//     .mount('#app')

// 452190390124-9p8d3o0b6qn48j35cdorlm0vokf0fi4p.apps.googleusercontent.com // intelipanel dev
// 740054991886-r00ijdkru3klff5pr58viahqmtd4j0lg.apps.googleusercontent.com
// 381559313865-ihfktasq5ih2b48dic3d6d59nmn1sr0b.apps.googleusercontent.com // current one
// 608578407854-kfib2i7q7aetg1jfr23uejfso5091tk9.apps.googleusercontent.com - test
// 452190390124-k0ja4f04iq47nbnjrpbnjp3l1ilf33pd.apps.googleusercontent.com //staging